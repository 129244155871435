// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://performance.beta.qa.zonarsystems.net',

  apiBase: {
    url: 'https://performance-api.qa.zonarsystems.net',
    tripApiUrl: 'https://trip-api.qa.zonarsystems.net',
    zonarUrl: 'https://api-qa.zonarsystems.net'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-qa.apigee.net'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: '0SlmXH46mEGoLeX0SXMCxTnCxgSvw1gx',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'b4198aa8-48c6-430c-8075-985bdebc7aeb',
    defaultZonarRole: '6c8df6f3-9070-4273-8672-1706ba6d9ae1',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_4aea8124_8744_4495_b494_54356a6bcafc_a3aa773'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '20c6b17b-0769-43ff-abc6-954875a39d97',
    clientToken: 'pub59c421a6b04b28afd8a978bcb891452b',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'it-IT', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  features: {
    'usage:summary': true,
    'usage:trend': true,
    'idle:summary': true,
    'idle:trend': true,
    'idle:top': true
  },
  here: {
    apiKeyV3: 'zRHQYeMWPTut1VrQIirpsuKQqaxgeY7dImB-CE0ND_0'
  },
  adminSettings: {
    url: 'https://settings.qa.zonarsystems.net/threshold-settings',
    applicationId: '19334f0c-89ff-4e7b-9017-3de175a16929'
  },
  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
